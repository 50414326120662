import React from "react";

const Footer = () => (
  <div className="hero-foot">
    <div className="container">
      <div className="tabs is-centered">
        <ul>
          <li>
            <a>{`© ${new Date().getFullYear()} - Julie Sidley CPA, EA, LLC`}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
);

export default Footer;
