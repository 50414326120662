import React from "react";

const Navbar = () => {
  const [isActive, setIsActive] = React.useState(false);
  return (
    <div className="hero-head">
      <nav className="navbar">
        <div className="container">
          <div className="navbar-brand">
            <span
              className={`navbar-burger burger ${isActive ? "is-active" : ""}`}
              data-target="navbarMenu"
              onClick={() => setIsActive(!isActive)}
            />
          </div>
          <div id="navbarMenu" className={`navbar-menu ${isActive ? "is-active" : ""}`}>
            <div className="navbar-end">
              <div className="tabs is-right">
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="https://juliesidleycpa.securefilepro.com/portal/#/login" target="_blank" rel="noreferrer">
                      Client Portal
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://secure.cpacharge.com/pages/juliesidleycpa/payments"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Pay Your Bill
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
